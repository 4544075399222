import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Chess from "./component/games/chess";
import Ludo from "./component/games/ludo/App";

import Load from "./component/Load";
import { static_blog_url } from "./constant/blogs_const";
import { blog_data } from "./dynamic_blog/content";
//import VideoChat from "./component/videoChat";

const Home = lazy(() => import("./App"));

//socket for video call
//this was causing two socket connection reason commenting

const Chat = lazy(() => import("./component/chat"));
const Lonly = lazy(() => import("./posts/lonly"));
const OnlineChess = lazy(() => import("./posts/online_chess"));
const OnlineLudo = lazy(() => import("./posts/online_ludo"));
const Loader = lazy(() => import("./OTTO/loader"));

const Breakup = lazy(() => import("./posts/breakup"));
const Posts = lazy(() => import("./posts/index"));
//const GAME_POST = lazy(() => import("./posts/game_post"));

const TALK_TO_STRANGER = lazy(() => import("./posts/tal_to_stranger"));
const DYNAMIC_BLOG = lazy(() => import("./posts/dynamic_blog"));
const Chat24article = lazy(() => import("./posts/chat24article"));
const CHAT_WITH_UNKNOW = lazy(() => import("./posts/chat_with_unknown"));
const CHAT_WITH_GIRLS = lazy(() => import("./posts/chat_with_girls"));
const CHAT_WITH_STRANGERS = lazy(() => import("./posts/chat_with_strangers"));
const NEW_DYNAMIC_BLOG = lazy(() => import("./dynamic_blog/index"));
//const blog_data = lazy(() => import("./dynamic_blog/content"));
const PAGE_NOT_FOUND = lazy(() => import("./posts/page_not_found"));
const BLOG_POSTER = lazy(() => import("./posts/blog_poster"));

export default function App() {
  const [urls, set_urls] = React.useState([]);

  React.useEffect(() => {
    console.log("======", blog_data);
    // commenting on 11-december-2022
    // since it was getting called from "chat.in" home page too many mongo db call was happening reason for mongo failure

    // // Dynamically binding routs
    // // this step is necessary to load blogs from mongo db
    // axios.get('' + config.api_url + '/get_blog_url').then((data) => {
    //   if(Array.isArray(data.data))
    //   {
    //     set_urls(data.data) // = set_urls([{url: "desktop-vs-laptop-what-should-you-buy-for-wfh"}])
    //   }
    // })

    // TODO : get this static_blog_url from mongo database
    // this is temporary sollution to avoid too many mongo db request
    set_urls(static_blog_url);
  }, []);

  return (
    <Router>
      <Suspense fallback={<Load />}>
        <div>
          <Switch>
            {urls.map((data, i) => {
              return (
                <Route key={i} path={data.url}>
                  <DYNAMIC_BLOG />
                </Route>
              );
            })}

            {Object.keys(blog_data).map((url, i) => {
              return (
                <Route key={i} path={url}>
                  <NEW_DYNAMIC_BLOG />
                </Route>
              );
            })}

            {/* <Route path="/">
            <Loading />
          </Route>  */}

            <Route path="/chess">
              <Chess />
            </Route>
            <Route path="/ludo">
              <Ludo />
            </Route>
            <Route path="/chat-24">
              <Chat24article />
            </Route>

            <Route path="/chat-with-random-girsl">
              <CHAT_WITH_GIRLS />
            </Route>
            <Route path="/chat-with-strangers">
              <CHAT_WITH_STRANGERS />
            </Route>
            <Route path="/chat-with-unknown-person-india">
              <CHAT_WITH_UNKNOW />
            </Route>
            <Route path="/blog-poster">
              <BLOG_POSTER />
            </Route>
            <Route path="/talk-to-strangers">
              <TALK_TO_STRANGER />
            </Route>
            {/* <Route path="/posts/play-games-with-random-friends">
              <GAME_POST />
            </Route> */}
            {/* <Route path="/posts/juul-vs-ncig">
              <JUUL_VS_NCIG />
            </Route> */}
            <Route path="/why-we-feel-lonely-sometimes">
              <Lonly />
            </Route>
            <Route path="/why-breakup-hurts">
              <Breakup />
            </Route>
            <Route path="/blog">
              <Posts />
            </Route>
            {/* <Route path="/video-chat">
              <VideoChat />
            </Route> */}
            <Route path="/chat">
              <Chat />
            </Route>
            {/* <Route path="/privacy-policy">
              <PRIVACY_POCICY />
            </Route> */}
            {/* <Route path="/terms-of-service">
              <TermsOfService />
            </Route> */}
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/online-chess">
              <OnlineChess />
            </Route>
            <Route path="/online-ludo">
              <OnlineLudo />
            </Route>
            <Route path="/Discover-New-Friends">
              <Loader />
            </Route>
            <Route path="*">
              <PAGE_NOT_FOUND />
            </Route>
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
}
